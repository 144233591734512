import React from 'react'
import Layout from 'src/components/Layout'
// Images
import image404 from 'assets/images/temps/404.png'

import pageContext from './pageContext.json'


const NotFound = () => {
  return (
    <Layout pageContext={pageContext}>
      <section className='py-5'>
        <div className='container pt-2 text-center text-lg-left'>
         <div className='row'>
          <div className='col-12'>
            <h3 className='fs-30 fs-lg-42 text-grayscale--500 mb-md-5'>Ops... página não encontrada!</h3>
          </div>
          <div className='col-sm-12 col-lg-5 col-xl-6 order-lg-2 pb-4 pb-lg-0 text-center'>
            <img className='img-fluid' src={image404} alt='404 image' />
          </div>
          <div className='col-sm-12 col-lg-7 col-xl-6 order-lg-1'>
            <p className='fs-18 lh-22 fs-lg-20 text-grayscale--500'>Aqui estão alguns links que você pode estar procurando:</p>
            <ul className='list-unstyled link-list--404 fw-400'>
              <li>
                <a href='/'>Home</a>
              </li>
              <li>
                <a href='/colecao-orange-nation'>
                  Roupas Orange Nation
                </a>
              </li>
            </ul>
           </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFound
